<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="setDrawer(!drawer)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />

    <v-spacer />

    <div class="mx-3" />

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-badge
            v-if="unreadnotifications > 0"
            color="red"
            overlap
            bordered
          >
            <template v-slot:badge>
              <span>{{ unreadnotifications }}</span>
            </template>

            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list
        :tile="false"
        nav
      >
        <div>
          <app-bar-item
            v-for="(notification, i) in notifications"
            :key="i"
          >
            <v-list-item-title
              v-if="notification.unread"
              :class="notification.unread? 'font-weight-bold' : ''"
              @click="$router.push({ path: '/notifications' })"
              v-text="notification.text"
            />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
  // Components
  import { VHover, VListItem } from 'vuetify/lib'

  // Utilities
  import { mapState, mapMutations } from 'vuex'
  const config = require('@/config')
  const axios = require('axios')

  export default {
    name: 'DashboardCoreAppBar',

    components: {
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text primary elevation-2': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default)
              },
            },
          })
        },
      },
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      notifications: [
        'Mike John Responded to your email',
        'You have 5 new tasks',
        'You\'re now friends with Andrew',
        'Another Notification',
        'Another one',
      ],
      unreadnotifications: 0,
      mytimer: null,
    }),

    computed: {
      ...mapState(['drawer']),
    },
    created: function () {
      var component = this
      component.updateNotifications()
      component.mytimer = setInterval(function () { component.updateNotifications() }, 8000)
    },
    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
      updateNotifications () {
        var component = this
        var currentJWT = localStorage.getItem(config.authToken)
        // var payload = jwt.decode(currentJWT)
        axios
          .get(config.backendURL + '/notifications', {
            headers: {
              authorization: 'Bearer ' + currentJWT,
            },
          })
          .then(function (response) {
            component.notifications = []
            response.data.notifications.forEach(element => {
              if (element.unread) {
                component.notifications.push(element)
              }
            })
            // component.notifications = response.data.notifications
            component.unreadnotifications = response.data.unread
            localStorage.setItem(config.authToken, response.data.jwt)
          })
          .catch(function (response) {
            console.log(response)
          })
      },
    },
  }
</script>
